.canvas-categories-list {
  display: inline-block;
  font-weight: var(--font--primary--bold);
  list-style: none;
  margin: 0 0 0 var(--space--dynamic--md);
  line-height: 1.5;
}

.canvas-categories-list__item {
  display: inline-block;
  margin: 0 var(--space--dynamic--sm) 0 0;
}

.canvas-categories-list__link {
  display: inline-block;
  background-color: var(--color--blue-light--300);
  border-top-right-radius: var(--settings--border-radius--sm);
  border-top-left-radius: var(--settings--border-radius--sm);
  color: var(--color--white);
  padding: var(--space--dynamic--sm) var(--space--dynamic--lg);
}

.canvas-categories-list__link:hover,
.canvas-categories-list__link:focus {
  background-color: var(--color--grey--500);
  color: var(--color--grey--100);
}

.canvas-categories-list__title {
  background-color: var(--color--blue-dark--500);
  border-top-right-radius: var(--settings--border-radius--sm);
  border-top-left-radius: var(--settings--border-radius--sm);
  color: var(--color--white);
  display: inline-block;
  padding: var(--space--dynamic--sm) var(--space--dynamic--lg);
}

.canvas-categories-list__icon {
  border-radius: 50%;
  border: 1px solid var(--color--white);
  display: inline-block;
  margin-left: var(--space--fixed--sm);
  width: 20px;
  height: 20px;
  overflow: hidden;
  line-height: 20px;
  text-align: center;
  vertical-align: bottom;
}

.canvas-categories-list__icon--selected {
  background-color: var(--color--green--500);
}

.canvas-categories-navigation-list {
  list-style: none;
  margin: 0;
}

.canvas-categories-navigation-list__item {
  display: inline-block;
}

.canvas-categories-navigation-list__item:not(:last-of-type) {
  margin-right: var(--space--fixed--sm);
}

.canvas-categories-navigation-list__faux-button,
.canvas-categories-navigation-list__link {
  cursor: pointer;
  font-size: var(--text--sm);
  font-weight: var(--font--primary--bold);
  color: var(--color--blue-dark--500);
}

.canvas-categories-navigation-list__save-button {
  background-color: var(--color--green--500);
  border-radius: var(--settings--border-radius--md);
  border: 0;
  color: var(--color--white);
  cursor: pointer;
  font-size: var(--text--sm);
  display: inline-block;
  font-weight: var(--font--primary--semibold);
  padding: var(--space--fixed--xs) var(--space--fixed--md);
}

.canvas-categories-navigation-list__icon {
  display: inline-block;
  margin-left: var(--space--dynamic--sm);
  vertical-align: sub;
}

.canvas-categories-navigation-list__inner {
  border-bottom: 1px solid var(--color--grey--500);}

.conversation-canvas-node {
  background: var(--color--white);
  border-radius: var(--settings--border-radius--sm);
  height: auto;
  padding: var(--space--dynamic--sm);
}

.conversation-canvas-node__handle.react-flow__handle-top {
  top: -9px;
}

.conversation-canvas-node__handle.react-flow__handle-bottom {
  bottom: -9px;
}

.react-flow__handle.conversation-canvas-node__handle {
  width: 15px;
  height: 15px;
  border-radius: 50%;
}

.react-flow__handle.conversation-canvas-node__handle--category {
  background-color: var(--color--orange--500);
}

.react-flow__handle.conversation-canvas-node__handle--why {
  background-color: var(--color--yellow--500);
  border: 1px solid var(--color--grey--300);
}

.react-flow__handle.conversation-canvas-node__handle--impact {
  background-color: var(--color--red--500);
}

.conversation-canvas-node__title {
  overflow: hidden;
  min-height: 30px;
  padding: var(--space--dynamic--sm);
}

.conversation-canvas-node__button {
  border-radius: 50%;
  border: 1px solid var(--color--white);
  cursor: initial !important;
  display: inline-block;
  float: right;
  height: 25px;
  text-align: center;
  width: 25px;
}

.conversation-canvas-node__title--impact {
  background-color: var(--color--red--500);
  color: var(--color--white);
}

.conversation-canvas-node__title--why {
  background-color: var(--color--yellow--500);
  color: var(--color--grey--500);
}

.conversation-canvas-node__title--category {
  background-color: var(--color--orange--500);
  color: var(--color--white);
}

.conversation-canvas-node-content {
  max-width: 250px;
}

.conversation-canvas-node-content__input {
  margin: 0;
}

.reactflow-wrap {
  background: var(--color--blue-light--100);
  position: relative;
}

.reactflow {
  height: 65vh;
  width: 100%;
}

.reactflow .react-flow {
  background: var(--color--blue-light--100);
}

.reactflow-aside {
  background-color: var(--color--blue-dark--500);
  border-radius: var(--settings--border-radius--sm);
  color: var(--color--white);
  font-size: var(--text--sm);
  left: 50px;
  padding: var(--space--fixed--sm);
  position: absolute;
  top: 50px;
}

.reactflow-aside__description {
  margin-bottom: var(--space--dynamic--xs);
}

.reactflow-aside__microcopy {
  margin: var(--space--dynamic--xs) 0 0;
}

.reactflow-aside-nodes-list {
  font-weight: var(--font--primary--bold);
  list-style: none;
  margin: 0;
}

.reactflow-aside-nodes-list__item {
  background: var(--color--white);
  border-radius: var(--settings--border-radius--sm);
  border: 4px solid var(--color--white);
  display: inline-block;
  height: auto;
  padding: var(--space--dynamic--xs) var(--space--dynamic--lg);
  cursor: grab;
}

.reactflow-aside-nodes-list__item--impact {
  background-color: var(--color--red--500);
  color: var(--color--white);
}

.reactflow-aside-nodes-list__item--why {
  background-color: var(--color--yellow--500);
  color: var(--color--grey--500);
  margin-right: var(--space--dynamic--sm);
}

.edgebutton {
  width: 20px;
  height: 20px;
  background: #eee;
  border: 1px solid #fff;
  cursor: pointer;
  border-radius: 50%;
  font-size: 12px;
  line-height: 1;
}

.edgebutton:hover {
  box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.08);
}

.edgebutton-foreignobject div {
  background: transparent;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 40px;
}

.canvas-category-controls-and-actions {
  margin-top: var(--space--fixed--md);
}

.canvas-category-controls-and-actions-header__title {
  border-bottom: 1px solid var(--color--blue--500);
  font-size: var(--text--lg);
  font-weight: var(--font--primary--light);
  margin-bottom: var(--space--fixed--md);
  padding-bottom: var(--space--fixed--md);
}

.canvas-category-controls-and-actions-header__emphasis--controls {
  color: var(--color--blue--700);
  font-weight: var(--font--primary--extrabold);
}

.canvas-category-controls-and-actions-header__emphasis--actions {
  color: var(--color--green--600);
  font-weight: var(--font--primary--extrabold);
}

.canvas-category-controls-and-actions-section-wrap {
  margin-bottom: var(--space--fixed--md);
}

.canvas-category-controls-and-actions-section {
  background-color: var(--color--grey--200);
  border: 2px solid var(--color--blue--700)
}

.canvas-category-controls-and-actions-section--left {
  border-top-left-radius: var(--settings--border-radius--lg);
  border-bottom-left-radius: var(--settings--border-radius--lg);
}

.canvas-category-controls-and-actions-section--right {
  border-top-right-radius: var(--settings--border-radius--lg);
  border-bottom-right-radius: var(--settings--border-radius--lg);
}

.canvas-category-controls-and-actions-section__inner {
  padding: var(--space--fixed--xl);
}

.canvas-category-controls-and-actions-section-item {
  background-color: var(--color--white);
  margin-bottom: var(--space--fixed--md);
}

.canvas-category-controls-and-actions-section-item__title {
  font-weight: var(--font--primary--bold);
  padding: var(--space--fixed--sm);
  position: relative;
}

.canvas-category-controls-and-actions-section-item__title--control {
  background-color: var(--color--blue--700);
  color: var(--color--white);
}

.canvas-category-controls-and-actions-section-item__title--action {
  background-color: var(--color--green--600);
  color: var(--color--white);
}

.canvas-category-controls-and-actions-section-item__delete-button {
  background-color: var(--color--white);
  border-radius: 50%;
  border: 2px solid var(--color--grey--300);
  color: var(--color--green-turquoise--800);
  cursor: pointer;
  display: inline-block;
  font-size: var(--text--xs);
  font-weight: var(--font--primary--bold);
  height: 25px;
  line-height: 23px;
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  width: 25px;
}

.canvas-category-controls-and-actions-section-item__input {
  border: 2px solid var(--color--grey--300);
  margin: 0;
}

.canvas-category-controls-and-actions-section-item__input--action {
  margin-bottom: var(--space--fixed--md);
}

.canvas-category-controls-and-actions-section-item__styles {
  padding: var(--space--fixed--md);
}

.canvas-category-controls-and-actions-section-button-wrap {
  text-align: center;
  padding: var(--space--fixed--md) 0;
}

.canvas-category-controls-and-actions-section-button {
  border-radius: var(--settings--border-radius--md);
  border: 0;
  color: var(--color--white);
  cursor: pointer;
  display: inline-block;
  font-weight: var(--font--primary--semibold);
  line-height: 1.5;
  padding: var(--space--fixed--xs) var(--space--fixed--lg);
}

.canvas-category-controls-and-actions-section-button--control {
  background-color: var(--color--blue--700);
}

.canvas-category-controls-and-actions-section-button--action {
  background-color: var(--color--green--600);
}

.canvas-category-controls-and-actions-section-button__icon {
  display: inline-block;
  margin-left: var(--space--fixed--md);
}

.canvas-category-footer-wrap {
  border-bottom: 1px solid var(--color--blue--500);
  border-top: 1px solid var(--color--blue--500);
  margin: var(--space--fixed--lg) 0;
  padding: var(--space--fixed--md) 0;
}

.canvas-category-footer-wrap--top {
  border: 0;
  text-align: center;
}

.canvas-category-footer-wrap__title {
  display: inline-block;
  margin-right: var(--space--fixed--md);
  font-size: var(--text--xl);
  font-weight: var(--font--primary--bold);
}

.canvas-category-footer-button {
  background-color: var(--color--green--500);
  border-radius: var(--settings--border-radius--md);
  border: 0;
  color: var(--color--white);
  cursor: pointer;
  display: inline-block;
  font-weight: var(--font--primary--semibold);
  line-height: 1.5;
  padding: var(--space--fixed--xs) var(--space--fixed--md);
}

.canvas-category-footer-button__icon {
  display: inline-block;
  margin-left: var(--space--fixed--md);
}

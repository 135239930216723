.basic-form--background {
  background-color: var(--color--grey--100);
  border-radius: var(--settings--border-radius--md);
  padding: var(--space--fixed--lg);
}

.basic-form__title {
  border-bottom: 1px solid var(--color--grey--300);
  color: var(--color--green-turquoise--500);
  font-size: var(--text--xl);
  font-weight: var(--font--primary--extrabold);
  line-height: var(--text--line-height--heading);
  margin-bottom: var(--space--fixed--md);
  padding-bottom: var(--space--fixed--sm);
}

.basic-form__subtitle {
  color: var(--color--green-turquoise--500);
  font-size: var(--text--lg);
  font-weight: var(--font--primary--extrabold);
  line-height: var(--text--line-height--heading);
}

.basic-form__faux-label {
  line-height: 1.1;
  margin-bottom: var(--space--fixed--xs);
}

.basic-form__label {
  cursor: pointer;
  margin-bottom: var(--space--fixed--xs);
}

.basic-form__label--sr-only {
  height: 1px;
  left: -10000px;
  overflow: hidden;
  position: absolute;
  top: auto;
  width: 1px;
}

.basic-form__input::placeholder {
  font-size: var(--text--sm);
}

.basic-form__input {
  border-radius: var(--settings--border-radius--sm);
  border: 1px solid var(--color--grey--300);
}

.basic-form__button {
  background-color: var(--color--green--500);
  border-radius: var(--settings--border-radius--md);
  border: 0;
  color: var(--color--white);
  cursor: pointer;
  display: inline-block;
  font-weight: var(--font--primary--semibold);
  line-height: 1.5;
  padding: var(--space--fixed--xs) var(--space--fixed--xl);
}

.basic-form__button:hover,
.basic-form__button:focus {
  background-color: var(--color--green--700);
  color: var(--color--white);
}

.basic-form__button:active {
  background-color: var(--color--green--800);
  color: var(--color--white);
}

.basic-form__button--multiple {
  margin-right: var(--space--fixed--md);
}

.basic-form__button--secondary {
  background-color: var(--color--grey--500);
  color: var(--color--white);
}

.basic-form__button--secondary:hover,
.basic-form__button--secondary:focus {
  background-color: var(--color--grey--700);
  color: var(--color--white);
}

.basic-form__button--secondary:active {
  background-color: var(--color--grey--900);
  color: var(--color--white);
}

.basic-form__button--with-icons-right .category-form-field-notes__icons {
  margin-left: var(--space--fixed--md);
}

.basic-form__footer {
  margin-top: var(--space--fixed--md);
}

.basic-form__field--row {
  border-bottom: 1px solid var(--color--grey--300);
  margin-bottom: var(--space--fixed--lg);
  margin-top: var(--space--fixed--lg);
}

.basic-form__field--inline,
.basic-form__actions--inline {
  display: inline-block;
  margin-right: var(--space--fixed--sm);
}

.basic-form__radio-button {
  appearance: none;
  background-color: var(--color--white);
  border-radius: 50%;
  border: 1px solid var(--color--grey--500);
  color: var(--color--blue-dark--500);
  cursor: pointer;
  height: 25px;
  margin: 0;
  width: 25px;
}

.basic-form__radio-button:checked {
  background-color: var(--color--blue--500);
}

.basic-form__radio-button:focus,
.basic-form__radio-button:focus-within {
  border-color: var(--color--blue--300);
  border-width: 2px;
  outline: 2px solid var(--color--blue--500);
}

.basic-form__faux-checkbox {
  -moz-transform: scale(2);
  -ms-transform: scale(2);
  -o-transform: scale(2);
  -webkit-transform: scale(2);
  margin: 20px 10px;
  padding: 10px;
  transform: scale(2);
}

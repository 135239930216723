.conversations-filter-form__input {
  background: var(--color--grey--200) url('../../../images/components/icons/search.png') no-repeat center left 10px / 20px auto;
  border-radius: var(--settings--border-radius--sm);
  border: 2px solid var(--color--green--500);
  box-shadow: none;
  height: 51px;
  margin: 0;
  padding-left: 40px;
  width: 100%;
}

.conversations-filter-form__button {
  background-color: var(--color--grey--500);
  border-radius: var(--settings--border-radius--sm);
  border: none;
  height: 51px;
  line-height: 1.1;
  margin: 0;
  width: 100%;
}

/* @see https: //codepen.io/stoepke/pen/QOOqGW */
.util--progress-bar {
  margin: 0 auto;
  overflow: hidden;
  padding: 2px;
  position: fixed;
  top: 0;
  width: 100%;
}

.util--progress-bar::before {
  bottom: -4px;
  content: '';
  left: -4px;
  position: absolute;
  right: -4px;
  top: -4px;
}

.util--progress-bar__inner {
  animation: progressBar 1s linear infinite;
  background: var(--color--blue--500);
  bottom: 0;
  left: 0;
  max-width: 250px;
  position: absolute;
  right: 100%;
  top: 0;
  width: 0;
}

@keyframes progressBar {
  0% {
    left: 0;
    right: 100%;
    width: 0;
  }

  10% {
    left: 0;
    right: 75%;
    width: 25%;
  }

  90% {
    left: 75%;
    right: 0;
    width: 25%;
  }

  100% {
    left: 100%;
    right: 0;
    width: 0;
  }
}

.primary-footer-wrap {
  background-color: var(--color--blue-dark--300);
  margin-top: var(--space--fixed--lg);
  font-size: var(--text--sm);
  padding-bottom: var(--space--fixed--xxl);
  padding-top: var(--space--fixed--lg);
  color: var(--color--white);
}

.primary-footer__image {
  margin-right: var(--space--fixed--xxl);
}

.footer-primary-microcopy-list {
  list-style: none;
  margin: 0 0 var(--space--fixed--xl);
  text-align: right;
}

.footer-primary-microcopy-list__item {
  margin-bottom: var(--space--fixed--xs);
}

.question-categories-action {
  border-radius: var(--settings--border-radius--md);
  background-color: var(--color--blue-light--100);
  color: var(--color--grey--500);
  padding: var(--space--fixed--xl) var(--space--fixed--lg);
  margin-bottom: var(--space--fixed--md);
}

.question-categories-action__title {
  font-size: var(--text--md);
  padding-bottom: var(--space--fixed--md);
  margin-bottom: var(--space--fixed--md);
  border-bottom: 3px solid var(--color--green-alternative--500);
}

.question-categories-action__styles p:last-of-type {
  margin-bottom: 0;
}

.question-categories-action--emphasis {
  background-color: var(--color--green--500);
  color: var(--color--white);
}

.question-categories-action--emphasis .question-categories-action__title {
  border-bottom-color: var(--color--white);
}

.question-categories-action__circle {
  display: inline-block;
  height: 40px;
  width: 40px;
  line-height: 40px;
  background-color: var(--color--green--500);
  font-weight: var(--font--primary--extrabold);
  text-align: center;
  color: var(--color--white);
  border-radius: 50%;
  text-transform: uppercase;
  margin-right: var(--space--fixed--sm);
  font-size: var(--text--sm);
}

.question-categories-list {
  list-style: none;
  font-size: var(--text--md);
  font-weight: var(--font--primary--extrabold);
  margin: 0 0 0 var(--space--fixed--md);
}

.question-categories-list__item {
  margin-bottom: var(--space--fixed--xs);
}

.question-categories-list__icon {
  margin-right: var(--space--fixed--md);
}

.category-form-field__title--change,
.question-categories-list__item--change {
  color: var(--color--green-turquoise--800);
}

.category-form-field__title--relationships,
.question-categories-list__item--relationships {
  color: var(--color--green-alternative--500);
}

.category-form-field__title--demands,
.question-categories-list__item--demands {
  color: var(--color--green--500);
}

.category-form-field__title--role,
.question-categories-list__item--role {
  color: var(--color--green-turquoise-alternative--500);
}

.category-form-field__title--peer-support,
.question-categories-list__item--peer-support {
  color: var(--color--green-turquoise-alternative-dark--500);
}

.category-form-field__title--management-support,
.question-categories-list__item--management-support {
  color: var(--color--blue--500);
}

.category-form-field__title--control,
.question-categories-list__item--control {
  color: var(--color--blue--300);
}

.question-categories-choice {
  background-color: var(--color--blue-dark--500);
  border-radius: var(--settings--border-radius--md);
  padding: var(--space--fixed--lg);
  margin-bottom: var(--space--fixed--lg);
  margin-top: var(--space--fixed--sm);
}

.question-categories-choice__title {
  color: var(--color--white);
  font-size: var(--text--md);
  font-weight: var(--font--primary--bold);
}

.question-categories-choice__header {
  margin-bottom: var(--space--fixed--md);
}

.question-categories-choice__styles {
  color: var(--color--white);
  margin-top: var(--space--fixed--md);
}

.question-categories-choice__styles a {
  color: var(--color--white);
  text-decoration: underline;
}

.question-categories-choice .category-form-field-wrap {
  padding-top: var(--space--fixed--md);
}

.question-categories-choice .basic-form__button--secondary {
  background-color: var(--color--grey--100);
  color: var(--color--grey--500);
}

body {
  color: var(--color--black);
}

.page-content--margin {
  margin-top: var(--space--fixed--md);
}

.page-content__header {
  margin-bottom: var(--space--fixed--md);
}

.page-content__header--bordered {
  border-bottom: 4px solid var(--color--blue-dark--500);
  margin-bottom: 0;
}

.page-content__header--fixed {
  background-color: var(--color--white);
  position: sticky;
  top: 0;
  z-index: 9999;
}

.page-content__title {
  color: var(--color--green-turquoise--500);
  font-size: var(--text--xxl);
}

.page-content__title--with-subtext {
  margin-bottom: var(--space--fixed--md);
}

.page-content__title-subtext {
  color: var(--color--black);
  font-weight: var(--font--primary--regular);
  font-size: var(--text--sm);
}

.page-content__subtitle {
  color: var(--color--green-turquoise--500);
  font-size: var(--text--lg);
}

.page-content__subtitle--small {
  font-size: var(--text--md);
  font-weight: var(--font--primary--bold);
}

.page-content__subtitle--section {
  border-bottom: 1px solid var(--color--blue--300);
  color: var(--color--grey--500);
  font-weight: var(--font--primary--light);
  margin-bottom: var(--space--fixed--md);
  padding-bottom: var(--space--fixed--sm);
}

.page-content__styles--emphasis {
  font-weight: var(--font--primary--semibold);
}

.page-content__button {
  background-color: var(--color--green--500);
  border-radius: var(--settings--border-radius--md);
  border: 0;
  color: var(--color--white);
  cursor: pointer;
  display: inline-block;
  font-weight: var(--font--primary--semibold);
  line-height: 1.5;
  padding: var(--space--fixed--xs) var(--space--fixed--xl);
}

.page-content__button--secondary {
  background-color: var(--color--blue--500);
  color: var(--color--white);
}

.page-content__button--inline {
  margin-right: var(--space--fixed--sm);
}

.page-content__button--bold {
  border-radius: var(--settings--border-radius--lg);
  padding: var(--space--fixed--sm) var(--space--fixed--md);
  font-weight: var(--font--primary--bold);
  font-size: var(--text--md);
}

.page-content__button--with-icon {
  padding: var(--space--fixed--sm) var(--space--fixed--lg) var(--space--fixed--sm) var(--space--fixed--sm);
}

.page-content__button-icon-wrap {
  background: var(--color--blue-dark--500);
  border-radius: 50%;
  display: inline-block;
  height: 35px;
  margin-right: var(--space--fixed--lg);
  overflow: hidden;
  padding: var(--space--fixed--sm);
  vertical-align: middle;
  width: 35px;
  line-height: 1;
  text-align: center;
}

.page-content__button-icon {
  display: inline-block;
  margin-left: var(--space--fixed--sm);
}

.page-content__button:hover,
.page-content__button:focus {
  background-color: var(--color--green--700);
  color: var(--color--white);
}

.page-content__button:active {
  background-color: var(--color--green--800);
  color: var(--color--white);
}

.page-content__button--secondary:hover,
.page-content__button--secondary:focus {
  background-color: var(--color--blue--700);
  color: var(--color--white);
}

.page-content__button--secondary:active {
  background-color: var(--color--blue--900);
  color: var(--color--white);
}

.ReactModal__Content {
  margin: 0 auto;
  max-width: 75%;
}

.page-notice {
  background-color: var(--color--green-turquoise--200);
  border-radius: var(--settings--border-radius--md);
  padding: var(--space--fixed--md);
  margin-bottom: var(--space--fixed--lg);
}

.page-notice__title {
  color: var(--color--green-turquoise--500);
  font-size: var(--text--md);
}

.page-notice__content p:last-of-type {
  margin-bottom: 0;
}

.page-notice__content a {
  color: var(--color--black);
  text-decoration: underline;
}


.page-content-list {
  list-style: none;
  margin: 0 0 var(--space--fixed--md);
}

.page-content-list--bold {
  font-weight: var(--font--primary--bold);
}

.canvas-content-wrap {
  margin-top: var(--space--fixed--sm);
}

.util--hide-icon {
  display: none !important;
}

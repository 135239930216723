.category-form-field-wrap {
  height: 100%;
  padding-bottom: var(--space--fixed--md);
}

.category-form-field {
  background-color: var(--color--grey--100);
  border-radius: var(--settings--border-radius--md);
  height: 100%;
  padding: var(--space--fixed--lg) var(--space--fixed--lg) calc(var(--space--fixed--lg) + 27px);
  position: relative;
}

.category-form-field--selected {
  background-color: var(--color--grey--200);
}

.category-form-field--empty {
  background-color: var(--color--blue-dark--300);
  height: auto;
  padding: 0;
}

.category-form-field__title {
  font-size: var(--text--lg);
  font-weight: var(--font--primary--extrabold);
  line-height: var(--text--line-height--heading);
}

.category-form-field__label--self-guided {
  font-size: var(--text--sm);
}

.category-form-field__input--self-guided {
  font-size: var(--text--xl);
  padding: var(--space--fixed--sm);
  margin: 0 0 var(--space--fixed--md);
  font-weight: var(--font--primary--extrabold);
  width: 100%;
}

.category-form-field__button {
  background-color: var(--color--grey--500);
  border-radius: var(--settings--border-radius--md);
  border: 0;
  color: var(--color--white);
  cursor: pointer;
  display: inline-block;
  font-weight: var(--font--primary--semibold);
  line-height: 1.5;
  padding: var(--space--fixed--xs) var(--space--fixed--sm) var(--space--fixed--xs) var(--space--fixed--md);
}

.category-form-field__button--secondary {
  background-color: var(--color--blue-dark--300);
  padding: var(--space--fixed--md);
  font-weight: var(--font--primary--semibold);
  display: block;
  width: 100%;
}

.category-form-field__button:hover,
.category-form-field__button:focus {
  color: var(--color--white);
}

.category-form-field__button:active {
  color: var(--color--white);
}

.category-form-field__button-icon {
  border-radius: 50%;
  vertical-align: sub;
}

.category-form-field__button-icon--empty {
  margin-left: var(--space--fixed--md);
}

.category-form-field__button-icon--select {
  margin-left: var(--space--fixed--md);
  background-color: var(--color--green--500);
}

.category-form-field__button-icon--deselect {
  color: var(--color--grey--500);
  margin-left: var(--space--fixed--sm);
}

.category-form-field__icon {
  color: inherit;
  cursor: pointer;
  display: inline-block;
  margin-right: var(--space--fixed--md);
}

.category-form-field__styles {
  margin-top: var(--space--fixed--sm);
}

.category-form-field__styles--notes {
  color: var(--color--blue-dark--500);
}

.category-form-field__styles--selected {
  font-size: var(--text--sm);
}

.category-form-field__styles--deselected {
  padding: var(--space--fixed--md) var(--space--fixed--xxl);
}

.category-form-field__input {
  border-radius: var(--settings--border-radius--sm);
  border: 1px solid var(--color--grey--300);
}

.category-form-field__link {
  border-bottom: 1px solid var(--color--grey--500);
  color: var(--color--grey--500);
  font-weight: var(--font--primary--bold);
  font-size: var(--text--sm);
  cursor: pointer;
}

.category-form-field__link:hover,
.category-form-field__link:focus {
  border-bottom-color: var(--color--blue--500);
}

.category-form-field__link:focus {
  border-bottom-color: var(--color--blue--700);
}

.category-form-field-notes__button {
  background: var(--color--white);
  border-radius: var(--settings--border-radius--sm);
  display: block;
  padding: var(--space--fixed--sm) var(--space--fixed--md);
  text-align: left;
  width: calc(100% - (var(--space--fixed--lg) * 2));
  cursor: pointer;
  font-weight: var(--font--primary--semibold);
  position: absolute;
  bottom: 10px;
}

.category-form-field-notes__icons {
  float: right;
}

.category-form-field-notes__checkmark {
  margin-left: var(--space--fixed--sm);
}

.category-form-field-notes__checkmark--checked {
  background-color: var(--color--green--500);
  border-radius: 50%;
  color: var(--color--white);
  border: 1px solid var(--color--grey--500);
}

.category-form-modal {
  height: 100%;
}

.category-form-modal__title {
  overflow: auto;
}

.category-form-modal__close-button {
  float: right;
  cursor: pointer;
}

.category-form-modal__button {
  background-color: var(--color--green--500);
  border-radius: var(--settings--border-radius--md);
  border: 0;
  color: var(--color--white);
  cursor: pointer;
  display: inline-block;
  font-weight: var(--font--primary--semibold);
  line-height: 1.5;
  padding: var(--space--fixed--xs) var(--space--fixed--xl);
}

.category-form-modal__field {
  height: calc(100% - 120px);
}

.category-form-modal__input {
  /* '!important' is required for the 'your private notes' modal due to being within the grid */
  height: 120px !important;
}

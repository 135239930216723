.primary-header-navigation-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

@media screen and (min-width: 64em) {
  .primary-header-navigation-list {
    display: inline-block;
    margin-right: var(--space--fixed--md);
  }
}

@media screen and (min-width: 64em) {
  .primary-header-navigation-list__item {
    display: inline-block;
  }
}

@media screen and (min-width: 64em) {
  .primary-header-navigation-list__link {
    padding: 0 var(--space--fixed--sm);
  }
}

.primary-header-navigation-list__link {
  display: inline-block;
  color: var(--color--grey--500);
  font-weight: var(--font--primary--bold);
}

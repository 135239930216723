.flash-message {
  color: var(--color--white);
  font-weight: var(--font--primary--bold);
  margin: var(--space--fixed--md) 0;
  padding: var(--space--fixed--sm);
}

.flash-message--notice {
  background-color: var(--color--green--500);
}

.flash-message--alert {
  background-color: var(--color--red--500);
}

.flash-message-compact {
  margin: var(--space--fixed--sm);
  color: var(--color--black);
  text-align: center;
}

.question-response-pagination-list {
  display: flex;
  list-style: none;
  margin: 0 0 var(--space--fixed--lg) 0;
  height: 8px;
  overflow: hidden;
}

.question-response-pagination-list__item {
  flex: 1 1 0;
}

.question-response-pagination-list__link,
.question-response-pagination-list__faux-link {
  border-radius: var(--settings--border-radius--sm);
  border: 1px solid var(--color--grey--200);
  display: block;
  height: 8px;
  text-indent: -9999px;
  width: 95%;
}

.question-response-pagination-list__link--previous {
  background-color: var(--color--green--500);
}

.question-response-pagination-list__link--next {
  background-color: var(--color--grey--300);
}

.question-response-pagination-list__faux-link--current {
  background-color: var(--color--blue--500);
}

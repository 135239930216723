@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;600;700;800&display=swap');

:root {
  /* ds: 2022-02-19:1 */

  --color--black: #000;
  --color--blue--300: #87c7d5;
  --color--blue--500: #1f9fc2;
  --color--blue--700: #187993;
  --color--blue--900: #14667c;
  --color--blue-dark--300: #667a81;
  --color--blue-dark--500: #1a3a45;
  --color--blue-light--100: #e3f3f3;
  --color--blue-light--300: #accaca;
  --color--blue-light--500: #8acad6;
  --color--yellow--500: #fbed8a;
  --color--green--500: #3ab54a;
  --color--green--600: #36895d;
  --color--green--700: #25742f;
  --color--green--800: #1e5e26;
  --color--green-alternative--500: #9ebe7f;
  --color--green-turquoise--200: #ddf1ee;
  --color--green-turquoise--500: #29a893;
  --color--green-turquoise--800: #5e8f8c;
  --color--green-turquoise-alternative--500: #7bc3ad;
  --color--green-turquoise-alternative-dark--500: #18a08a;
  --color--grey--100: #f9f9f9;
  --color--grey--200: #f5f5f5;
  --color--grey--300: #d1d1d1;
  --color--grey--500: #203a37;
  --color--grey--700: #142523;
  --color--grey--900: #010202;
  --color--orange--500: #F59260;
  --color--red--500: #c15360;
  --color--white: #fff;

  --font--primary: 'Nunito Sans', sans-serif;
  --font--primary--light: 300;
  --font--primary--regular: 400;
  --font--primary--semibold: 600;
  --font--primary--bold: 700;
  --font--primary--extrabold: 800;

  --settings--border-radius--unit: 1rem;
  --settings--border-radius--sm: calc(0.5 * var(--settings--border-radius--unit));
  --settings--border-radius--md: calc(1 * var(--settings--border-radius--unit));
  --settings--border-radius--lg: calc(1.5 * var(--settings--border-radius--unit));

  --text--line-height--heading: 1.1;
  --text--line-height--body: 1.6;

  --text--base-size: 1em;
  --text--scale-ratio: 1.2;
  --text--sm: calc(1em / var(--text--scale-ratio));
  --text--md: calc(1em * var(--text--scale-ratio));
  --text--lg: calc(var(--text--md) * var(--text--scale-ratio));
  --text--xl: calc(var(--text--lg) * var(--text--scale-ratio));
  --text--xxl: calc(var(--text--xl) * var(--text--scale-ratio));
  --text--xxxl: calc(var(--text--xxl) * var(--text--scale-ratio));
  --text--xs: calc(var(--text--sm) / var(--text--scale-ratio));

  --space--dynamic--unit: 1em;
  --space--dynamic--xs: calc(0.25 * var(--space--dynamic--unit));
  --space--dynamic--sm: calc(0.5 * var(--space--dynamic--unit));
  --space--dynamic--md: calc(1 * var(--space--dynamic--unit));
  --space--dynamic--lg: calc(1.5 * var(--space--dynamic--unit));
  --space--dynamic--xl: calc(2 * var(--space--dynamic--unit));
  --space--dynamic--xxl: calc(2.5 * var(--space--dynamic--unit));

  --space--fixed--unit: 1rem;
  --space--fixed--xs: calc(0.25 * var(--space--fixed--unit));
  --space--fixed--sm: calc(0.5 * var(--space--fixed--unit));
  --space--fixed--md: calc(1 * var(--space--fixed--unit));
  --space--fixed--lg: calc(1.5 * var(--space--fixed--unit));
  --space--fixed--xl: calc(2 * var(--space--fixed--unit));
  --space--fixed--xxl: calc(2.5 * var(--space--fixed--unit));
}

body {
  font-family: var(--font--primary);
  font-size: var(--text--base-size);
  line-height: var(--text--line-height--body);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font--primary);
  font-weight: var(--font--primary--extrabold);
  line-height: var(--text--line-height--heading);
}

a {
  color: var(--color--blue--500);
}

a:focus,
a:hover {
  color: var(--color--blue--700);
}

a:active {
  color: var(--color--blue--800);
}

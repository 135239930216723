.js--data-table-wrap > div {
  max-height: initial;
}

.js--data-table-wrap .rdt_Table,
.js--data-table-wrap .rdt_TableRow {
  font-size: var(--text--base-size);
}

.js--data-table-wrap .rdt_TableHeadRow .rdt_TableCol {
  background: var(--color--blue-dark--500);
  border-left: 1px solid var(--color--white);
  color: var(--color--white);
  font-weight: var(--font--primary--bold);
}

.js--data-table-wrap .rdt_TableHeadRow .rdt_TableCol:first-of-type {
  border-top-left-radius: var(--settings--border-radius--lg);
}

.js--data-table-wrap .rdt_TableHeadRow .rdt_TableCol:last-of-type {
  border-top-right-radius: var(--settings--border-radius--lg);
}

.js--data-table-wrap .rdt_TableBody .rdt_TableCell {
  color: var(--color--blue-dark--500);
  font-weight: var(--font--primary--semibold);
}

.js--data-table-wrap .rdt_TableBody .rdt_TableRow {
  border-bottom: 0 !important;
}

.js--data-table-wrap .rdt_TableBody .rdt_TableRow .rdt_TableCell {
  background: var(--color--grey--200);
  border: 1px solid var(--color--white);
  padding-bottom: var(--space--fixed--md);
  padding-top: var(--space--fixed--md);
  margin-bottom: var(--space--fixed--xs);
  margin-top: var(--space--fixed--xs);
}

.js--data-table-wrap .rdt_TableBody .rdt_TableRow .rdt_TableCell:first-of-type {
  border-top-left-radius: var(--settings--border-radius--sm);
  border-bottom-left-radius: var(--settings--border-radius--sm);
}

.js--data-table-wrap .rdt_TableBody .rdt_TableRow .rdt_TableCell:last-of-type {
  border-top-right-radius: var(--settings--border-radius--sm);
  border-bottom-right-radius: var(--settings--border-radius--sm);
}

.js--data-table-wrap header {
  display: block;
  width: 100%;
  padding: 0;
}

.js--data-table-wrap .rdt_TableCol_Sortable div {
  white-space: pre-line !important;
  word-wrap: break-word;
}

.data-table__icon-link {
  display: block;
  width: 100%;
  color: var(--color--black);
  overflow: auto;
}

.data-table__icon-link--pending {
  color: var(--color--black);
}

.data-table__icon-link--in-progress {
  color: var(--color--blue--500);
}

.data-table__icon-link--complete {
  color: var(--color--green--500);
}

.data-table__icon-link--inactive {
  opacity: .25;
}

.data-table__icon {
  display: inline-block;
  margin-left: var(--space--fixed--sm);
  vertical-align: bottom;
  float: right;
}

.data-table__status-string {
  display: inline-block;
  margin-top: var(--space--fixed--sm);
}

.data-table__faux-button {
  background-color: var(--color--green--500);
  border-radius: var(--settings--border-radius--md);
  border: 0;
  color: var(--color--white);
  cursor: pointer;
  display: inline-block;
  float: right;
  font-weight: var(--font--primary--semibold);
  line-height: 1.2;
  min-width: 125px;
  overflow: auto;
  padding: var(--space--fixed--sm) var(--space--fixed--md);
}

.data-table__faux-button:hover,
.data-table__faux-button:focus {
  background-color: var(--color--green--700);
  color: var(--color--white);
}

.data-table__faux-button:active {
  background-color: var(--color--green--800);
  color: var(--color--white);
}

.data-table__faux-button--pending {
  background-color: var(--color--grey--500);
}

.data-table__faux-button--pending:hover,
.data-table__faux-button--pending:focus {
  background-color: var(--color--grey--700);
}

.data-table__faux-button--pending:active {
  background-color: var(--color--grey--900);
}

.data-table__faux-button--in-progress {
  background-color: var(--color--blue--500);
}

.data-table__faux-button--in-progress:hover,
.data-table__faux-button--in-progress:focus {
  background-color: var(--color--blue--700);
}

.data-table__faux-button--in-progress:active {
  background-color: var(--color--blue--900);
}

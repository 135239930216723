
/* Container holding the image and the text */
.image-container {
  margin-top:40px;
  position: relative;
  text-align: center;
  border-bottom:2px;
}

/* Centered text */
.image-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
 /*  background: rgba(41, 168, 147, 0.95); */
  background: rgba(58, 181, 74, 0.95);
  padding:30px;
  color:#ffffff;
}

.image-overlay:hover {
  background: rgba(37, 116, 47, 0.95);
}